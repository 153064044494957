@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;700&family=Bebas+Neue&family=Cairo:wght@300;700&family=Edu+VIC+WA+NT+Beginner&family=Montserrat:wght@100&family=Mouse+Memoirs&family=Orbitron&family=Oswald:wght@200&family=Play&family=Rajdhani:wght@300;700&family=Teko:wght@300;400&family=Titillium+Web:ital,wght@0,700;1,200&display=swap');
*{
  /* font-family: 'Advent Pro', sans-serif; */
  /* font-family: 'Oswald', sans-serif; */
  font-family: 'Rajdhani', sans-serif;
  margin:0; padding:0;
  box-sizing: border-box;
  outline: none; border:none;
  transition: all .2s linear;
}
body{
  animation: fadeInLeft 1s linear;
}
header{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
}
.navbar{
  width: 90%;
  height: 95%;
  display: flex;
  border-bottom: 3px solid gainsboro;
  border-top: 3px solid gainsboro;
  border-radius: 1rem;
}
.navbar--left{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar--logo{
  width: 200px;
  height: 80px;
}
.navbar--logo img{
  width: 100%;
  height: 100%;
}
.navbar--right{
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.navbar--right--content{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.slogan{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  color: white;
  animation: fadeInDown 5s linear;
}
.slogan > p{
  font-size: 2rem;
}
.main--part{
  width: 100%;
  height:100vh;
  margin-bottom: 0.5rem;
  position: relative;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1462899006636-339e08d1844e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzR8fGJ1aWxkaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60'); */
}
video{
  position: absolute;
  width: 100%;
  height:100%;
  left: 0;
  top: 0;
  z-index: -1;
}
/* .container--header{
  width: 100%;
  text-align: center;
  border-bottom: 1px solid darkgray;
  font-weight: 600;
} */
.container--content{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 flex-wrap: wrap;
}
.cards{
  width: 340px;
  /* height: 390px; */
  padding: 0.6rem;
  margin-bottom: 1rem;
  animation:fadeInUp 2s linear;
  margin: 0.5rem;
  border-bottom: 1px solid gainsboro;
  border-radius: 1rem;
}
.card__mb{
  width: 300px;
  /* height: 390px; */
  padding: 0.6rem;
  animation:fadeInUp 2s linear;
  margin: 0.5rem;
  border-radius: 0.7rem;
  border: 1px solid gainsboro;
}
p{
  margin-bottom: 0 !important;
}
.cards--box--header{
  width: 100%;
  height: 50%;
  border-radius: 0.4rem;
}
.cards--box--header:hover{
  width: 100%;
  height: 45%;
  border-radius: 0.4rem;
  transform: scale(1.2);
  margin-bottom: 1rem;
}
.cards--box--header img{
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
}
.cards--box--content{
  width: 100%;
  height: 50%;
  padding: 10px;
  text-align: center;
}
.home{
  font-size: 1.2rem;
  font-weight: bold;
  color: coral;
}
.home--name{
  /* font-size: 1.5rem; */
  font-weight: bold;
}

.home--price > span{ 
  color: coral;
  /* font-size: 1.5rem; */
  font-weight: bold;
}
.more--details{
  width: 200px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 0.9rem;
  /* background-color:#d8a765; */
  background-color:rgb(12, 52, 103);
  color: white;
  animation: pulse 2s infinite;
  cursor: pointer;
}
.more--details:hover{
  background-color:white;
  color: rgb(12, 52, 103);
  border: 1px solid rgb(12, 52, 103);
}
.liste{
  padding: 1rem;
  text-decoration: none;
  color:rgb(12, 52, 103);
  font-weight: bold;
  font-size: 1.1rem;
}
.na{
  text-decoration: none;
  color:rgb(12, 52, 103);
  font-weight: bold;
  font-size: 1.1rem;
}
.na:hover{
  color: coral;
}
.liste:hover{
  color: coral;
}
.main--contact{
  width: 100%;
  padding: 0.7rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: fadeInUp .7s linear;
  
  background-image: url('https://images.unsplash.com/photo-1528747045269-390fe33c19f2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80');
}
.main--contact--content{
  width: 100%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
}
.main--contact--content--right{
  width: 60%;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  backdrop-filter: blur(2rem);
  color: #fff;
  /* border: 1px solid coral; */
  display: flex;
}
.main--contact--content--left{
  width: 40%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
}
.main--contact--content--right--box{
  width: 100%;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  /* backdrop-filter: blur(2rem); */
  color: #fff;
  /* border: 1px solid coral; */
}
.form__container{
  width: 80%;
  height: 90%;
  border: 1px solid gainsboro;
  padding: 1rem;
  border-radius: 0.7rem;
   backdrop-filter: blur(2rem);
}
.header__contact{
  color: white;
  margin-left: 4rem;
  /* font-weight: bold; */
}
.form-label{
  color: white;
}
.description--enterprise{
  width: 100%;
  display: flex;
  background-color: rgb(238, 236, 236);
  animation:fadeInLeft 2s linear;
}
.description--enterprise--content--left{
  width: 60%;
  padding: 1rem;
}
/* .description--enterprise--content--left > p{
  font-weight: bold;
} */
.description--enterprise--content--left > ul li{
  font-weight: bold;
}
.description--enterprise--content--right{
  width: 40%;
  border-radius: 10px;
  padding:2rem;
}
.description--enterprise--content--right img{
  width: 100%;
  border-radius: 10px;
}
.name--enterprise{
  color: coral;
  font-weight: bold;
}
.name--project{
  font-size: 2rem;
  color: rgb(12, 52, 103);
  font-style: italic;
  font-weight: bold;
}
.footer{
  width: 100%;
  padding: 10px 170px;
  /* background-color:rgb(12, 52, 103); */
  color: rgb(12, 52, 103);
  font-weight: bold;
  background-color: rgb(245, 242, 242);
}
.footer__title{
  font-weight: bold;
}
.foot--content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-around;
  border-top: 5px solid coral;
}
.schedule{
  height: 200px;
  margin-bottom: 1rem;
  text-align: center;
}
.address{
  height: 200px;
  text-align: center;
  margin-bottom: 1rem;
}
.social--media{
  height: 200px;
  text-align: center;
  cursor: pointer;
}
.social--media i{
  padding-top: 0.3rem ;
  padding-right: 0.3rem;
  font-size: 1.3rem;
}
.name--developer{
  width: 100%;
  text-align: end;
}
.right{
  width: 100%;
  text-align: center;
}
.question--part{
  width: 100%;
  height: 50vh;
  /* background-color:rgb(12, 52, 103); */
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: url('https://images.unsplash.com/photo-1423666639041-f56000c27a9a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1774&q=80');
  animation:fadeInRight 3s linear;
}
.question--part--content{
  width: 90%;
  height: 80%;
  padding: 3rem;
  text-align: center;
}
.question--part--content--box{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid coral;
}
.question--part--btn{
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.btn--contact{
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: coral;
  margin-bottom: 10px;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  animation: pulse 2s infinite;
}
.part--newsletter{
  width: 100%;
  height: 400px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(250, 248, 248);
}
.part--newsletter--left{
  width: 40%;
  height: 100%;
  /* border: 1px solid coral; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}
.part--newsletter--left--box{
  width: 350px;
  height: 350px;
  border-radius: 0.5rem;
}
.part--newsletter--left--box img{
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
.part--newsletter--right{
  width: 55%;
  height: 100%;
  border: 1px solid coral;
  text-align: center;
  padding: 2rem;
}
.box--newsletter{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid coral;
}
.slogan__title{
  font-size: 3rem;
}
/* h3{
  font-size: 1rem;
  margin-bottom: 10px;
  color: #000;
} */
.question{
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
}
.container--home{
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom:1px solid gray ;
  margin-top: 10px;
  margin-bottom: 10px;
}
.container--home--left--part{
  width: 50%;
  /* height: 700px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.container--home--left--part--content{
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.container--home--left--part--content--image{
  width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container--home--left--part--content--image--box{
  width: 500px;
  height: 400px;
  border-radius: 0.8rem;
}
.container--home--left--part--content--image--box img{
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}
.container--home--left--part--content--images{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.images--thumbails{
  height: 90%;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.images--thumbails--box{
  width: 125px;
  height: 125px;
  border-radius: 0.8rem;
}
.images--thumbails--box img{
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}
.container--home--right--part{
  width: 47%;
  padding: 1.5rem;
  border: 1px solid rgb(254, 250, 250);
  background-color: rgb(254, 250, 250);
}
.button--block{
  height: 50px;
  display: flex;
  margin-top: 10px;
}
/* h6{
  font-weight: bold;
  font-size: 1.5rem;
  color: coral;
  text-decoration: underline;
} */
.btn--buy{
  padding: 10px;
  font-size: 1rem;
  border-radius: 0.5rem;
  width: 200px;
  background-color: coral;
  color: white;
}
.btn--buy:hover{
  background-color: rgb(12, 52, 103);
  border: 1px solid coral;
  color: white;
}
.container--home--second--part{
  width: 100%;
}
.md{
  color: #fff;
  font-size: 3rem;
}
.container__rent__homes{
  width: 100%;
  height: 300px;
  background-image: url('https://images.unsplash.com/photo-1522431266699-83e4a2a82b16?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80');
  background-position: center;
  /* border: 1px solid coral; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.container__rent__homes__header{
  width: 70%;
  height: 70%;
  border-bottom: 1px solid coral;
  color: white;
  text-align: center;
  font-weight: bold;
}
.main__rent__homes{
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* border-top: 1px solid gainsboro; */
  margin: 1rem 0;
  padding: 1rem;
}
.main__rent__home__right{
  width: 79%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* border: 1px solid gainsboro; */
  flex-wrap: wrap;
}
.filter--home{
  width: 20%;
  height: 100vh;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* border: 1px solid gainsboro; */
  position: sticky;
  top: 0;
  padding: 0.3rem;
}
.filter--home__title{
  font-size: 1.2rem;
  /* color: rgb(12, 52, 103); */
  font-weight: bold;
}
.filter--home--content{
  width: 100%;
  /* border: 1px solid coral; */
  padding: 0.5rem;
}
.filter--home--content--box{
  width: 100%;
  border-radius: 0.3rem;
  border-bottom:2px solid rgb(12, 52, 103);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(12, 52, 103); */
  cursor: pointer;
}
.filter--home--content--box:hover{
  color: white;
  background-color: rgb(12, 52, 103);
}
/* .selected__container{
  width: 100%;
  margin: 1rem 0;
  border: 3px solid gainsboro;
} */
.btn__filter{
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100%;
  color: rgb(15, 52, 90);
  margin-bottom: 1rem;
  /* font-weight: bold; */
  cursor: pointer;
}
.btn__filter:hover{
  color: coral;
  margin-bottom: 1rem;
  font-weight: bold;
}
/* .menu{
  display: none;
} */
/* .contacts{
  position: fixed;
  width: 50px;
  height: 50px;
  top: 30%;
  right: 0;
  z-index: 999;
  background-color: #d8a765;
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
} */
/* .card--contact{
  position: fixed;
  width: 70%;
  z-index: 10000;
  top: 50px;
  margin: 0 auto;
  right: -1000px;
  left: 0;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid #d8a765;
  background-color: gainsboro;
  transition: ease-in 0.8s;
  box-shadow: 0px 0px 10px black;
} */
/* .show--CardContact{
  top:40px;
  right: 0;
  transition: ease-in 0.8s;
} */
.sidebar{
  position: fixed;
  right:-2000px;
  top: 0;
  width:300px;
  height: 100vh;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  transition: ease-in-out .7s;
  justify-content: center;
  background-color:white;
  /* box-shadow: 0px 0px 9px 5px black; */
  z-index: 10000;
}
.show-sidebar{
  right:0;
  transition: ease-in .7s;
  /* transform: translate(-100vw); */
}

.sidebar--menu {
  width: 90%;
  height: 90%;
  display: block;
  text-align: center ;
  text-decoration: none;
  color: #000;
  font-weight: bold;
}
.sidebar--menu > p{
  padding: 1rem;
}
.btn--close--sidebar{
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
}

.main--part--project{
  width: 100%;
  height: 70vh;
  padding: 1rem 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInLeft 1.5s linear;
  font-weight: bold;
}
h5{
  font-weight: bold;
}
.main--part--project--left--part{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid gainsboro;
}
.main--part--project--right--part{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid coral; */
}
.main--part--project--right--part--content{
  width: 100%;
  height: 50%;
  margin-left: 1rem;
}
.name--home{
  font-size: 2.5rem;
  /* font-weight: bold; */
  font-style: italic;
  text-transform: uppercase;
  color:rgb(12, 52, 103);
}
.top--houses{
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  color:rgb(12, 52, 103);
  border-bottom: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
  margin: 1rem 0;
}
.btn--details{
  padding: 0.6rem;
  color: white;
  width: 200px;
  margin-top: 0.5rem;
  border-radius: 0.3rem;
  background-color: coral;
  font-weight: bold;
}
.btn--details:hover{
  padding: 0.6rem;
  background-color: rgb(12, 52, 103);
  color:white;
}
.project--pictures{
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.project--pictures--box{
  width: 550px;
  height: 475px;
  border-radius: 0.3rem;
  animation: fadeInUp 2s linear;
}
.project--pictures--box img{
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  transform: scale(0.9);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.project--pictures--box:hover img{
  transform: scale(1);
}
.main--part--detail--project{
  width: 100%;
  /* height: 500px; */
  margin-top: 1rem;
  /* padding: 1rem 10rem; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.project--name{
 width: 100%;
 margin-left: 2rem;
 /* border-bottom: 1px solid gainsboro; */
}
.title{
  color: #000;
  font-size: 2rem;
  font-weight: bold;
}
.detail--project--content{
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gainsboro;
}
.detail--project--content--left{
  width: 50%;
  height: 100%;
  cursor: pointer;
}
.detail--project--content--left img{
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: 0.5s ease-in-out;
}
.detail--project--content--left:hover img{
  transform: scale(1.02);
}
.detail--project--content--right{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.images--container{
  width: 50%;
  height: 50%;
  /* border: 1px solid coral; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.images--container--box{
  width: 380px;
  height: 255px;
}
.images--container--box img{
  width: 100%;
  height: 100%;
}
.description--project{
  width: 100%;
  padding: 1rem 11rem;
}
.icons{
  display: none;
}
.icon--search{
  width: 50%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  color:rgb(12, 52, 103);
  font-size: 1.5rem;
}
.icon--menu{
  width: 50%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  color:rgb(12, 52, 103);
  font-size: 1.5rem;
  cursor: pointer;
}
.icon--search:hover{
  color: coral;
}
.icon--menu:hover{
  color: coral;
}
.main__part__conditions{
  width: 100%;
  height: 500px;
  padding: 0.7rem;
  /* border: 1px solid coral; */
}
.desc--header{
  font-weight: bold;
  font-size: 1.5rem;
  color: coral;
  text-decoration: underline;
}
.main__part__conditions__header{
  font-weight: bold;
  font-size: 1.3rem;
  text-decoration: underline;
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.filter__container{
  width: 30%;
  height: 50px;
  padding: 0.7rem;
  display: none;
  align-items: center;
  margin: 1rem 0;
}
.btn__filter{
  padding: 0.7rem;
  border-radius: 0.5rem;
  color: rgb(12, 52, 103);
}
.filter__bar{
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -400px;
  z-index: 1000;
  padding: 0.5rem;
  background-color: #fff;  
  transition: ease-in-out .7s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.show__filter__bar {
  left: 0;
  transition: ease-in-out .7s;
}
.filter__bar__content{
    width: 100%;
    height: 50%;
    /* padding: 1rem; */
    /* border: 1px solid coral; */
}
.filter__items{
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin: 0.7rem 0;
  width: 100%;
}
.filter__btn__close{
  width: 100%;
  height: 40px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  /* border: 1px solid coral; */
  color: cadetblue;
}
.main__part__concierge{
  width: 100%;
  height: 330px;
  background-image: url('https://service-nettoyage-entretien.com/wp-content/uploads/2021/09/nettoyage-de-surfaces-commerciales-1024x684-1.jpeg');
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content:center;
  color: white;
}
.section__concierge{
  width: 100%;
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content:center;
}
.section__concierge__left{
  width: 60%;
  padding: 1rem;
}
.section__concierge__right{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content:center;
}
.section__concierge__right__content{
  width: 80%;
  height: 250px;
  /* border: 1px solid coral; */
  border-radius: 0.5rem;
}
.section__concierge__right__content img{
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
.filter__header{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter__header__content{
  width: 70%;
  height: 80%;
}
.filter__header__content img{
  width: 100%;
  height: 100%;
}
#but{
  font-weight: bold;
  color:rgb(12, 52, 103);
  border-bottom: 1px solid gainsboro;
  padding: 1rem;
}
#but:hover{
  color:coral;
}
.container__realisations{
  width: 100%;
  padding: 0.5rem;
  margin: 0.7rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* border: 1px solid cadetblue; */
}
/* .container__realisations__content{
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid cadetblue;
} */
.cards__realisations{
  width: 340px;
  height: 230px;
  margin: 0.5rem;
  border-radius: 0.4rem;
  border: 1px solid cadetblue;
}
.cards__realisations img{
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
}
.real_dis{
  width: 100%;
  height: 100vh;
  position: relative;
}
.main--part--detail--realisations{
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1rem 0;
}
.detail--cards{
  width: 300px;
  height: 200px;
  margin: 0.5rem;
  border-radius: 0.5rem;
}
.detail--cards img{
  width: 100%;
  height: 100%;
}
.container__real__header{
  width: 100%;
  height: 200px;
  background-image: url('https://images.unsplash.com/photo-1522431266699-83e4a2a82b16?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80');
  background-position: center;
  /* border: 1px solid coral; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.container__real__header__content{
  width: 70%;
  height: 80%;
  border-bottom: 1px solid coral;
  color: white;
  text-align: center;
  font-weight: bold;
}
.footer--link{
  color: rgb(15, 52, 90);
  text-decoration: none;
}


@media only screen and (max-width:575px){

  .main--part{
    height:35vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  nav{
    margin-left: 1rem;
  }
  video{
    position: absolute;
    width: 100%;
    height:100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
  /* .real_dis{
    width: 100%;
    height: 300px;
  } */
  .navbar--right--content{
    display: none;
  }
  .navbar--left{
    width: 50%;
    cursor: pointer;
  }
  .navbar--right{
    width: 50%;
    justify-content: end;
  }
  .icons{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container-fluid img{
    width: 100px;
    height: 50px;
  }
  .navbar--logo{
    width: 60%;
    height: 60%;
  }
  .slogan{
    top: 40%;
  }
  .slogan > p{
    font-size: 1.2rem;
    font-weight: bold;
  }
  .slogan__title{
    font-size: 1.5rem;
    font-weight: bold;
  }
  /* h3{
    font-size: 1rem;
    margin-bottom: 10px;
    color: #000;
  } */
  .question{
    font-size: 1.7rem;
  }
 .container{
  padding: 0;
}
.row{
 display: block;
}
.cards{
  width: 100%;
  padding: 10px;
}
.md{
  font-size: 2rem;
  margin: 1rem 0;
}
.description--enterprise{
  width: 100%;
  padding: 2rem;
  display: block;
  background-color: rgb(245, 240, 240);
}
.description--enterprise--content--left{
  width: 100%;
  padding:0;
}
.description--enterprise--content--right{
  width: 100%;
  padding: 0;
  margin-top: 5px;
}
.question--part{
  height: 40vh;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}
.question--part--content{
  padding: 0;
}
.main--contact{
  width: 100%;
  height: auto;
  display: block;
  padding: 1rem 2.5rem;
}
.main--contact--content{
  width: 100%;
  height: 90%;
  display: block;
}
.form__container{
  width: 100%;
  padding: 0.3rem;
}
.header__contact{
  color: white;
  margin-left: 0rem;
}
.main--contact--content--right{
  width: 100%;
  margin: 1rem 0;
  border-radius: 0.7rem;
  /* border: 1px solid coral; */
}
.main--contact--content--left{
  width: 100%;
  padding: 0;
}
.main__part__conditions{
  width: 100%;
  height: auto;
  padding: 0.7rem;
  /* border: 1px solid coral; */
}
.main__rent__homes{
  width: 100%;
  display: block;
  /* border: 1px solid coral; */
  margin: 1rem 0;
  padding: 1rem;
}
.main__rent__home__right{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid gainsboro; */
  flex-wrap: wrap;
}
.filter__container{
  display: flex;
}
.filter--home{
  display: none;
}
.container__rent__homes{
  height: auto;
}
.container__rent__homes__header{
  width: 100%;
  height: 75%;
  border-bottom: 1px solid coral;
  color: white;
  text-align: center;
  font-weight: bold;
}
.container__rent__homes__header p{
  font-size: 0.5rem;
}
.project--name{
  width: 100%;
  margin-left: 0.7rem;
  /* border-bottom: 1px solid gainsboro; */
 }
 .cards__realisations{
  width: 330px;
  height: 200px;
  border-radius: 0.7rem;
  border: 1px solid cadetblue;
}
.real_dis{
  height: 40vh;
  position: relative;
}
.container--home{
  display: block;
}
.container--home--left--part{
  width: 100%;
}
.container--home--left--part--content{
  width: 95%;
  height: 95%;
}
.container--home--left--part--content--image{
  width: 100%;
}
.container--home--left--part--content--image--box{
  width: 300px;
  height: 300px;
}
.container--home--left--part--content--image--box img{
  width: 100%;
  height: 100%;
}
.container--home--left--part--content--images{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.container--home--right--part{
  width: 100%;
}
.footer{
  width: 100%;
  padding: 0;
}
/* .footer p{
  margin: 10px;
} */
.foot--content{
  display: block;
}
.schedule{
  height: 100px;
}
.filter--home--content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card--contact{
  width: 90%;
  padding: 1rem;
}
.main--part--project{
  height: 80vh;
  padding: 1rem ;
  display: block;
}
.main--part--project--left--part{
  width: 100%;
  height: 60%;
}
.main--part--project--right--part{
  width: 100%;
  height: 38%;
  border-bottom: none;
  display: flex;
}
.main--part--project--right--part--content{
  height: 100%;
}
.project--pictures--box{
  width: 550px;
  height: 280px;
}
.detail--project--content{
  width: 100%;
  height: 100vh;
  display: block;
}
.detail--project--content--left{
  width: 100%;
  height: 35%;
  cursor: pointer;
}
.detail--project--content--right{
  width: 100%;
  height: 60%;
}
.images--container{
  width: 50%;
  height: 50%;
}
.images--container--box{
  width: 380px;
  height: 180px;
}
.description--project{
  padding: 1rem 1rem;
}
.main--part--detail--project{
  padding: 1rem ;
}
.name--home{
  font-size: 1.2rem;
  text-align: center;
}
.top--houses{
  font-size: 1.3rem;
}
.section__services__content{
  height: auto;
  display: block;
}

}
@media only screen and (min-width:576px) and (max-width:766px){

  .main--part{
    height:70vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .navbar--right--content{
    display: none;
  }
  .navbar--left{
    width: 30%;
    cursor: pointer;
  }
  .navbar--right{
    width: 70%;
    justify-content: end;
  }
  .slogan{
    top: 30%;
  }
  .slogan > p{
    font-size: 1.5rem;
  }
  h2{
    font-size: 1.5rem;
    color: #d8a765;
  }
  h3{
    font-size: 1rem;
    margin-bottom: 10px;
    color: #000;
  }
 .container{
  padding: 0;
}
.row{
 display: block;
}
.cards{
  width: 100%;
  padding: 5px;
}
.description--enterprise{
  width: 100%;
  padding: 2rem;
  display: block;
  background-color: rgb(245, 240, 240);
}
.description--enterprise--content--left{
  width: 100%;
  padding:0;
}
.description--enterprise--content--right{
  width: 100%;
}
.question--part{
  height: 70vh;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}
.question--part--content{
  padding: 0;
}
.main--contact{
  width: 100%;
  height: auto;
  display: block;
}
.main--contact--content{
  width: 100%;
  height: 90%;
  display: block;
}
.main--contact--content--right{
  width: 100%;
}
.main--contact--content--left{
  width: 100%;
}
.container--home{
  display: block;
}
.container--home--left--part{
  width: 100%;
}
.container--home--left--part--content{
  width: 95%;
  height: 95%;
}
.container--home--left--part--content--image{
  width: 100%;
}
.container--home--left--part--content--image--box{
  width: 300px;
  height: 300px;
}
.container--home--left--part--content--image--box img{
  width: 100%;
  height: 100%;
}
.container--home--left--part--content--images{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.images--thumbails{
  width: 22%;
}
.images--thumbails--box{
  width: 70px;
  height: 70px;
}
.container--home--right--part{
  width: 100%;
}
footer{
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 0.8rem;
}
}
@media only screen and (min-width:767px) and (max-width:1086px){

  .navbar--right--content{
    display: flex;
  }
  .card__mb{
    width: 220px;
    font-size: 0.8rem;
  }
  .more--details{
    width: 150px;
    padding: 0.3rem;
    border-radius: 0.5rem;
  }
  .project--pictures--box{
    width: 550px;
    height: 390px;
    border-radius: 0.3rem;
    animation: fadeInUp 2s linear;
  }
  .slogan{
    top: 30%;
  }
  .slogan > p{
    font-size: 1.5rem;
  }
 .container{
  padding: 0;
}
.row{
 display: block;
}
.cards{
  width: 45%;
  padding: 10px;
}
.description--enterprise{
  width: 100%;
  padding: 2rem;
  display: block;
  background-color: rgb(245, 240, 240);
}
.description--enterprise--content--left{
  width: 100%;
  padding:0;
}
.description--enterprise--content--right{
  width: 100%;
}
.question--part{
  height: 40vh;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}
.question--part--content{
  padding: 0;
}
.main--contact{
  width: 100%;
  height: auto;
  display: block;
}
.main--contact--content{
  width: 100%;
  height: 90%;
  display: block;
}
.main--contact--content--right{
  width: 100%;
}
.main--contact--content--left{
  width: 100%;
}
.container--home{
  display: block;
}
.container--home--left--part{
  width: 100%;
}
.container--home--left--part--content{
  width: 95%;
  height: 95%;
}
.container--home--left--part--content--image{
  width: 100%;
}
.container--home--left--part--content--image--box{
  width: 300px;
  height: 300px;
}
.container--home--left--part--content--image--box img{
  width: 100%;
  height: 100%;
}
.container--home--left--part--content--images{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.images--thumbails{
  width: 22%;
}
.images--thumbails--box{
  width: 70px;
  height: 70px;
}
.container--home--right--part{
  width: 100%;
}
.main--part--project{
  height: 70vh;
  padding: 1rem ;
  display: flex;
}
.detail--project--content{
  height: 50vh;
}
.images--container{
  width: 50%;
  height: 50%;
  /* border: 1px solid coral; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.images--container--box{
  width: 380px;
  height: 190px;
}
.project--pictures{
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer{
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 0.8rem;
}
}
